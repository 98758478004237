* {
    -webkit-touch-callout: none;
    -webkit-user-select: none; /* Disable selection/copy in UIWebView */
}

body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}

.leaflet-container {
	/*
	width: 100vw;
	height: calc(100vh - 56px);
	*/
	left: 0px;
    right: 0px;
    top: 50px;
    bottom: 56px;
}

#root {
	margin-bottom: 56px;
}

#importText,
#order,
#text,
#ml {
    -webkit-user-select: all;
}
